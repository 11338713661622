function showsendfriendmodal(product_id, path_web, type) {
    var elname = type === "look_basket" ? "modbox" : "sendfriendbox";
    var actualposition = document.documentElement.scrollTop;

    if (type !== 'look_basket') {
        var psnok = document.getElementById('product_sendfriend_nok');
        var psfok = document.getElementById('product_sendfriend_ok');
        if (psnok) {
            psnok.innerHTML = '';
            psnok.style.display = 'none';
        }
        if (psfok) {
            psfok.innerHTML = '';
            psfok.style.display = 'none';
        }

        prodGetProductDisplay(product_id, path_web, type);

        openMultiShad("sendfriendbox");
        $("#shad_abox").addClass('actif');

    } else {
        show_lightbox(elname);
        $(".loader", "#bloc_add_basket").hide();
    }
}

function prodGetProductDisplay(id, path_web, type) {
    if (typeof type == "undefined") {
        var response = ajax_file(
            path_web + create_link('ajax_get_infos_send_friend') + '?product_id=' + id
        );
        document.getElementById("sendfriend_prodinfos").innerHTML = response;
    } else {
        if (type == "look") {
            $("#titre_popup").html(Translator.translate('sendfriend_title_look'));
            $("#friend_form").show();
            $("#btn_popup_panier").hide();

            $("#bloc_btn_look").show();
            $("#bloc_btn_loader_look").hide();
        } else if (type == "look_basket") {
            $("#titre_popup").html("Vous venez d'ajouter le look suivant");
            $("#friend_form").hide();
            $("#btn_popup_panier").show();

            $("#bloc_btn_look").show();
            $("#bloc_btn_loader_look").hide();
        } else {
            $("#titre_popup").html("Vous venez d'ajouter le produit suivant");
            $("#friend_form").hide();
            $("#btn_popup_panier").show();
        }
    }

    if (
        navigator.userAgent.indexOf("MSIE") != -1 &&
        navigator.userAgent.indexOf("6.") != -1
    ) {
        var svn = document.getElementsByTagName("SELECT");
        for (a = 0; a < svn.length; a++) {
            svn[a].style.visibility = "hidden";
        }
    }
}

$(function () {
    $('#shad_abox').on("click", function () {
        closeMultiShad();
        $('.lightbox.actif').removeClass('actif');
        $('body').css('overflow', 'auto');
    });

    // Fp bottom associations
    if ($('#gondole_complete.assocSwiper').length) {
        var sliderassocSwiper_swiper = new Swiper('#gondole_complete.assocSwiper', {
            slidesPerView : 3,
            setWrapperSize: true,
            roundLengths: true,
            //spaceBetween: 30,
            navigation: {
                nextEl: ".productAssoc .swiper-button-next",
                prevEl: ".productAssoc .swiper-button-prev",
            },
            breakpoints: {
                1200: {
                    slidesPerView: 4,
                    //spaceBetween: 50,
                },
                1400: {
                    slidesPerView: 5,
                    //spaceBetween: 20,
                },
            },
        });
    }

     //SWIPER FICHE PRODUIT CONTENU COFFRET
    if ($('#gondole_complete_coffrets.productContents').length && $('#gondole_complete_coffrets.productContents .swiper-slide').length >= 5) {
        $("#boxContent").find('.swiper-wrapper').css('justify-content','space-evenly');
        var sliderproductContent_swiper = new Swiper('#gondole_complete_coffrets.productContents', {
            slidesPerView : 3,
            setWrapperSize: true,
            roundLengths: true,
            //spaceBetween: 30,
            navigation: {
                nextEl: ".box_content .swiper-button-next",
                prevEl: ".box_content .swiper-button-prev",
            },
            breakpoints: {
                1200: {
                    slidesPerView: 4,
                    //spaceBetween: 50,
                },
                1400: {
                    slidesPerView: 5,
                    //spaceBetween: 20,
                },
            }
        });
    }

    if ($('#gondole_complete_coffrets.productContents .swiper-slide').length < 5) {
        $("#boxContent").find('.arrow').hide();
    }

});

// PRODUCT PAGE ZOOM
function zoomImg(slide) {
    var slide_img = $(slide);
    var slide;

    if (slide_img.hasClass('video_zoom_trigger')) {
        slide = slide_img.closest('.slick-slide');
    } else {
        slide = slide_img.parent('.slick-slide');
    }

    var slide_index = slide.data('slick-index') - 1;

    $('#zoom_box_wrapper').css('opacity', 0);

    window.setTimeout(function () {

        $('#zoom_box_wrapper').css('opacity', 1);
    }, 350);

    openMultiShad('zoomBox');

    // NB : slide - 1 because current slide isn't the big image but the one to its left
    if ($('.productZoomSlick').length) {
        if (!$('.productZoomSlick').hasClass('slick-initialized')) {
            $('.productZoomSlick').slick({
                slidesToShow: 6,
                lazyLoad: 'ondemand',
                initialSlide: slide_index
            });
        } else {
            $('.productZoomSlick').slick('slickGoTo', slide_index)

        }
        $('.productZoomSlick .slick-slide').on('click', function (e) {
            e.preventDefault();
            $('.productZoomSlick').slick('slickGoTo', ($(this).data('slick-index') - 1));
        });
    }
}

// Dans la fiche produit affiche un champs email pour que l'ont previenne que le produit est disponible
function lightboxAlertStock() {
    var $taille_id = $("#tailleProd").val(),
        $couleur_id = $("#couleurProd").val();

    if ($taille_id == '') {
        if (!$('#sizeerror').is(':visible')) {
            $('#sizeerror').slideDown();
        }
        return;
    } else if ($couleur_id == '') {
        alert(translate('js_error_couleur'));
        return;
    }

    var isFormVisible = $('.bloc_add_alert_form').is(':visible');
    var isMessageVisible = $('.bloc_add_alert_confirmation').is(':visible');
    var isErrorVisible = $('.bloc_add_alert_erreur').is(':visible');

    if (isFormVisible || isMessageVisible || isErrorVisible) {

        if (isMessageVisible) {
            $('.bloc_add_alert_confirmation').slideUp('fast');
        }

        if (isErrorVisible) {
            $('.bloc_add_alert_erreur').slideUp('fast');
        }
    } else {
        $('.bloc_add_alert_form').slideDown('fast');
    }
}

$('#bloc_add_alert #btn_add_alert').click(function () {
    $(this).hide();
});

$('.bloc_add_alert_confirmation button').click(function () {
    $('#bloc_add_alert #btn_add_alert').show();
});

function scrollTo( target ) {
    let heightHeader = $('#site_head_wrap').height();

    if (target.length) {
        $("html, body").animate({scrollTop: target.offset().top - (heightHeader + 120)}, 1500);
    }
}

if ($('.page_produit').length) {
    var productWrapper = $('.product_info_wrapper');
    var productInfos = $('form.product_info');
    var productInfosSticky = $('form.product_info.sticky');
    var buttonLabel = productInfos.find('#btn_add_cart.label_button_no_preco span');
    var productPrice = productInfos.find($('.item_price'));
    var productImg = $('#jqzoom .swiper-slide:not(.has-video):first-child');
    var headerTop = $('.head_top');

    productImg.clone().prependTo('#itm_name'); // Insert product image for sticky Add to cart
    productPrice.clone().appendTo(productInfos.find($('#btn_add_cart'))); // Insert product price in sticky Add to cart button

    // ANCRE
    $('.cta--secondary.coffret').click(function(e) {
        e.preventDefault();
        scrollTo($('#boxContent'));
    });

    // Add class + change button translator for sticky Add to cart
    var featsWrapper = $(".prod_top_wrapper");
    threshold = featsWrapper.height();
    

    $(window).on('scroll', function() {

        if ($(this).scrollTop() >= threshold) {
            productWrapper.addClass('sticky_eclats');
            productInfos.addClass('sticky');
            headerTop.addClass('removeShadow');
        } else {
            productWrapper.removeClass('sticky_eclats');
            productInfos.removeClass('sticky');
            headerTop.removeClass('removeShadow');
        }
    });
}

$('.share_social_media').click(function() {
    $('body').css('overflow', 'hidden');
});

$('#sendfriendbox .close_pop, #shad').click(function() {
    $('body').css('overflow', 'auto');
});

/**
 * Fetches and displays a list of stores in a modal box.
 * 
 * This function makes an AJAX request to the server to fetch a list of stores. The server responds with the list of stores, which the function injects into a modal box. The modal box is then displayed on top of a semi-transparent "shade" that covers the rest of the page.
 * 
 * If the user's browser supports geolocation, the function also initiates a proximity search to find stores near the user's current location.
 */
function showpdv() {

    var shade, modbox, contents;

    $.ajax({
        type: 'post',
        url: path_relative_root + create_link('ajax_search_shop'),
        success: function (res) {

            if (res) {
                shade = document.getElementById('shade');
                modbox = document.getElementById('pdvBox');

                contents = $('<div />').html(res);

                // Make sure modbox will show on top of shade
                $(shade).after(modbox);

                // Inject store locator into modbox
                $('.store_locator', modbox).html($('.storeloc_form', contents).add($('.liste_recherche_distributeur', contents)));

                // add different translations if on the product page (.product_page on the body)
                // this is a bit of a hack, but it's the easiest way to do it!
                if ($('body').hasClass('product_page')) {
                    $('#pdvBox .popup_title').text(Translator.translate('store_locator_stock_availabilty'));
                }

                initialize();

                $(modbox).addClass('actif');
                $(shad).addClass('actif');
                $(shad).on('click', closepdv);
                $('#content_pdvBox_new').hide();
                $('#content_pdvBox').show();

                // Show modbox and make sure closing routine won't fire more than once
                $(shade).off('click', closepdv).fadeIn(200, function () {
                    $(modbox).slideDown(600);
                    $(shade).on('click', closepdv);
                    $('#content_pdvBox_new').hide();
                    $('#content_pdvBox').show();
                    $(modbox).removeClass('showForm');
                    $(modbox).removeClass('thanks');

                    // Init locator
                    if (navigator.geolocation) {
                        var crd = navigator.geolocation.getCurrentPosition(rechercheProximite);
                    }
                });
            }
        }
    });
}

/**
 * Closes the store modal box.
 * 
 * This function removes the 'actif' class from the modal box and the shade, effectively hiding them from the user's view.
 */
function closepdv() {
    $('#pdvBox, #shad').removeClass('actif');
}
