
let arrayDeliveryData = {};

/**
 * Initializes the calendar for delivery date selection.
 *
 * @author  Stefane DR  <sdr@wshop.com>
 *
 * @param {string} conveyorId - The ID of the conveyor.
 * @param {boolean} isPreco - Indicates if the delivery is pre-cooked.
 * @param {number} remiseStock - The stock discount.
 *
 * @returns {void}
 */
function initCalendar(conveyorId, isPreco, remiseStock) {
    var inputTarget = $('#delivery-date-' + conveyorId);
    if (inputTarget.length) {
        var deliveryData = arrayDeliveryData[conveyorId] || {};
        var arrayDates = deliveryData.disabledDates ? deliveryData.disabledDates.split('|') : [];
        var hasUltraFrais = deliveryData.hasUltraFrais || '0';
        var grandeBuche = deliveryData.isGrandeBuche || '0';
        var petiteBuche = deliveryData.isPetiteBuche || '0';
        var patisserie = deliveryData.isPatisserie || '0';
        var patisserie2 = deliveryData.isPatisserie2 || '0';
        var storeClosingHours = deliveryData.storeClosingHours || '';

        var dateToday = new Date();

        if (inputTarget.attr('conveyor-id')) {
            conveyorId = inputTarget.attr('conveyor-id');
        }

        let minDate = dateToday;
        if (conveyorId == '24' && !isPreco) {
            minDate = new Date();
            minDate.setDate(dateToday.getDate() - 1);
        }

        array = checkHourlyRules(arrayDates, dateToday, conveyorId);
        inputTarget.datepicker({
            dateFormat: 'dd/mm/yy',
            minDate: dateToday,
            maxDate: new Date(dateToday.getFullYear(), dateToday.getMonth() + 3, 0),
            altField: '#order_delivery_date',
            altFormat: 'yy-mm-dd',
            firstDay: 1,
            beforeShowDay: function (date) {
                return (conveyorId == '24' && !isPreco)
                ? enablePickupDate(date, storeClosingHours, conveyorId, petiteBuche,  grandeBuche, patisserie, patisserie2)
                : enableDeliveryDate(date, arrayDates, hasUltraFrais, conveyorId, isPreco, remiseStock, grandeBuche, petiteBuche, patisserie, patisserie2);
            },
        });
    }
}

/**
 * We check if we have availability for this month
 *
 * @author  Youssef Mabrouk  <yma@wshop.com>
 *
 * @returns {void}
 */
function checkAvailibiltyCurrentMonth() {
    var currentDay = new Date();
    var nbrDaysOfCurrentMonth = this.daysInMonth(currentDay.getMonth()+1, currentDay.getFullYear());// "+1" because "getMonth" starts counting at 0, and goes until 11.
    var nbrDaysOfCurrentMonthUnselected = document.getElementsByClassName("ui-datepicker-unselectable").length - document.getElementsByClassName("ui-datepicker-other-month").length;
    var isNoAvailibilty = (nbrDaysOfCurrentMonth == nbrDaysOfCurrentMonthUnselected) ? true : false;
    if (isNoAvailibilty) {
        document.querySelector('.ui-datepicker-next').click();
    }
}

/**
 * We check the number of days of the current month. If we use the "year" param, it's because the specificity of february.
 *
 * @author  Youssef Mabrouk  <yma@wshop.com>
 *
 * @param   {integer}   month
 * @param   {integer}   year
 *
 * @returns {Date}
 */
function daysInMonth (month, year) {
    return new Date(year, month, 0).getDate();
}

/**
 * @description Check if the showing date is enabled or not, according to certain rules
 *
 * @author  Stefane DR  <sdr@wshop.com>
 *
 * @param   {string}    date
 * @param   {array}     array
 * @param   {boolean}   ultra_frais
 * @param   {integer}   conveyor_id
 * @param   {boolean}   isPreco
 * @param   {string}    remiseStock
 * @param   {string}    grandeBuche
 * @param   {string}    petiteBuche
 * @param   {string}    patisserie
 *
 * @returns {boolean}
 */
function enableDeliveryDate(date, array, ultraFrais, conveyorId = 0, isPreco, remiseStock, grandeBuche = '0', petiteBuche = '0', patisserie = '0', patisserie2 = '0') {
    // we recheck the store ID here ta take the shop change in count
    var storeIdNoel = $('#livr_relais').val();
    if (!storeIdNoel) {
        storeIdNoel = $('[name="livr_dom"]').val();
    }
    storeIdNoel = Number(storeIdNoel);

    //specifique date for the christmas pastry
    const { petiteBucheDates, grandeBucheDates } = getBucheDates(storeIdNoel);
    if (isPreco == "1") {
        var datePreco = $.datepicker.parseDate('dd/mm/yy', remiseStock);
        datePreco.setDate(datePreco.getDate() - 1);
        var formattedDatePrecoYear = $.datepicker.formatDate('yy-mm-dd', datePreco);
    }

    var formatDate = $.datepicker.formatDate('mm-dd', date);
    var formatDateYear = $.datepicker.formatDate('yy-mm-dd', date);
    var currentDay = date.getDay();
    conveyorId = (conveyorId != 0) ? conveyorId : $('#conveyor_id').val();
    var specialDates = ['04-09', '04-10', '04-15'];

    // "Saturday" or "Sunday" aren't working days for PM
    if ([6, 0].includes(currentDay) && conveyorId != 24) {
        return false;
    }

    // if it's a pre-order
    if (isPreco == '1') {
        if (formatDateYear < formattedDatePrecoYear) {
            return false;
        }
    }

    // If the selected conveyor is "Chrono Fresh"
    if (conveyorId == '51') {
        var arrayDaysHigh = [0, 1];
        var arrayDaysLow = [0, 1, 2, 4, 6];

        // enable all days except weekends
        if (formatDate >= '02-01' && formatDate <= '03-31') {
            return [array.indexOf(formatDate) == -1 && array.indexOf(formatDateYear) == -1];
        } else if (formatDate >= '09-01' && formatDate <= '06-30') {
            // if it's the "high season" we don't show : Sundays and Mondays
            if (arrayDaysHigh.includes(currentDay)) {
                return false;
            }
        } else if (formatDate >= '01-01' && formatDate <= '01-31') {
            // for january we show all working days
            if ([0, 6].includes(currentDay)) {
                return false;
            }
        } else if (formatDate >= '07-01' && formatDate <= '08-31') {
            // if it's the "low season" we only show : Wednesdays and Thursdays
            if (arrayDaysLow.includes(currentDay)) {
                return false;
            }
        }

        // until 29/09, the following days are deactivated: Sunday/ Monday/ Saturday
        if (formatDate < '10-06' && formatDate > '01-31' && [0, 1, 2, 6].includes(currentDay) && !specialDates.includes(formatDate)) {
            return false;
        }
    }

    // condition for "Ultra frais" products
    if (ultraFrais == '1') {
        var previousDay = formatDate - 1;
        if (currentDay == 1 || array.includes(previousDay)) {
            return false;
        }
    }

    // Deactivate the day following an undeliverable date, except for the days between 12/01 and 31/01
    if (formatDate >= '12-01' && formatDate <= '01-31') {
        var arrayPreviousDates = [];
        var previousDayInitial = $.datepicker.parseDate('mm-dd', formatDate);
        previousDayInitial.setDate(previousDayInitial.getDate() - 1);
        arrayPreviousDates.push($.datepicker.formatDate('mm-dd', previousDayInitial));
        // SW-16340 - DO NOT DELETE
        // if (['51', '37'].includes(conveyorId)) { 
        //     previousDayInitial.setDate(previousDayInitial.getDate() - 1);
        //     arrayPreviousDates.push($.datepicker.formatDate('mm-dd', previousDayInitial));
        // }

        if (array.some(v => arrayPreviousDates.indexOf(v) !== -1) && !specialDates.includes(formatDate)) {
            return false;
        }
    }

    if (conveyorId == '24') {
        // Conditions for "Petite Buche"
        if (petiteBuche == '1') {
            if (!petiteBucheDates.includes(formatDate)) {
                return false; // Disable if date is not in valid dates
            }
        }

        // Conditions for "Grande Buche"
        if (grandeBuche == '1') {
            if (!grandeBucheDates.includes(formatDate)) {
                return false; // Disable if date is not in valid dates
            }
        }

        // Conditions for Pastry (delivery on D+1 before 12 p.m.)
        if (patisserie == '1') {
            var tomorrow = new Date();
            tomorrow.setDate(date.getDate() + 1);
            var tomorrowFormatted = $.datepicker.formatDate('yy-mm-dd', tomorrow);

            // If the order is placed after 12 p.m., deactivate D+1
            if (new Date().getHours() >= 12 && formatDateYear == tomorrowFormatted) {
                return false;
            }
        }
        if (patisserie2 == '1') {
            var tomorrow = new Date(); 
            tomorrow.setDate(date.getDate() + 1);
            var tomorrowFormatted = $.datepicker.formatDate('yy-mm-dd', tomorrow);
            // Convert to Date object
            let parsedDate = new Date(formatDate);
            // Get the day of the week
            let day = parsedDate.getDay();
            // Disable Mondays (1) and Tuesdays (2)
            if (day == 1 || day == 2) {
                return false;
            }

            // If the order is placed after 12 p.m., deactivate D+1
            if (new Date().getHours() >= 12 && formatDateYear == tomorrowFormatted) {
                return false;
            }
        }
    }

    // Return array of valid dates
    return [array.indexOf(formatDate) == -1 && array.indexOf(formatDateYear) == -1];
}

/**
 * @description Check hourly rules for PM's conveyors
 *
 * @author  Stefane DR  <sdr@wshop.com>
 *
 * @param {Array}   array
 * @param {string}  date
 * @param {string}  conveyorId
 *
 * @returns {Array}
 */
function checkHourlyRules(array, date, conveyorId) {
    var hours = date.getHours();
    var arrayChronopost = ['51', '37'];
    var arrayFedex = ['36', '54'];
    var arrayDpd = ['52', '53'];

    // if the selected conveyor is "ChronoFresh" or "ChronoExpress"
    if (arrayChronopost.includes(conveyorId)) {
        // add D+1
        date.setDate(date.getDate() + 1);
        array.push($.datepicker.formatDate('yy-mm-dd', date));
        array = checkWorkingDays(date, array);
        if (hours >= 12) {
            // add D+2
            date.setDate(date.getDate() + 1);
            array.push($.datepicker.formatDate('yy-mm-dd', date));
            array = checkWorkingDays(date, array);
        }
    } else if (arrayFedex.includes(conveyorId) || arrayDpd.includes(conveyorId)) {
        array.push($.datepicker.formatDate('yy-mm-dd', date));
        array = checkWorkingDays(date, array);
        if (hours >= 8) {
            // add D+1
            date.setDate(date.getDate() + 1);
            array.push($.datepicker.formatDate('yy-mm-dd', date));
            array = checkWorkingDays(date, array);
        }
    }
    return array;
}

/**
 * Checks if current day is a working day
 *
 * @author  Stefane DR  <sdr@wshop.com>
 *
 * @param   {Datetime}  date
 * @param   {Array}     array
 *
 * @returns {Array}
 */
function checkWorkingDays(date, array) {
    currentDay = date.getDay();
    while ([6, 0].includes(currentDay)) {
        date.setDate(date.getDate() + 1);
        currentDay = date.getDay();
        array.push($.datepicker.formatDate('yy-mm-dd', date));
    }
    return array;
}

/**
 * Verifies the integrity of a date according to the given rules
 *
 * @author Stefane DR <sdr@wshop.com>
 *
 * @param {string} date - The date to verify.
 * @param {string} conveyorId - The ID of the conveyor.
 *
 * @returns {boolean}
 */
function verifyDateIntegrity(date, conveyorId) {
    date = new Date(date);
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    if (date < today) {
        return false;
    }

    let deliveryData = arrayDeliveryData[conveyorId] || {};
    let arrayDates = deliveryData.disabledDates ? deliveryData.disabledDates.split('|') : [];
    let hasUltraFrais = deliveryData.hasUltraFrais || '0';
    let grandeBuche = deliveryData.isGrandeBuche || '0';
    let petiteBuche = deliveryData.isPetiteBuche || '0';
    let patisserie = deliveryData.isPatisserie || '0';
    let patisserie2 = deliveryData.isPatisserie2 || '0';
    let isPreco = deliveryData.isPreco || '';
    let remiseStock = deliveryData.remiseStock || '';
    let storeClosingHours = deliveryData.storeClosingHours || '';

    let verifDate = (conveyorId == '24' && !isPreco)
        ? enablePickupDate(date, storeClosingHours,  conveyorId, grandeBuche, petiteBuche, patisserie, patisserie2)
        : enableDeliveryDate(date, arrayDates, hasUltraFrais, conveyorId, isPreco, remiseStock, grandeBuche, petiteBuche, patisserie, patisserie2);
    return verifDate;
}

/**
 * Checks if a given date is within the next 10 days and the store isn't closed on that day.
 *
 * @author Stefane DR <sdr@wshop.com>
 *
 * @param {Date} date - The date to check.
 * @param {string} storeClosingHours - The date when the store is closed in ISO string format.
 * @param {string} conveyorId - The ID of the conveyor.
 * @param {string} petiteBuche - Indicates if the product is a "Petite Buche".
 * @param {string} grandeBuche - Indicates if the product is a "Grande Buche".
 * @param {string} patisserie - Indicates if the product is a "Patisserie".
 * @param {string} patisserie2 - Indicates if the product is a "Patisserie2".
 *
 * @returns {boolean} Returns true if the date is within the next 10 days and the store isn't closed on that day, otherwise false.
 */
function enablePickupDate(date, storeClosingHours, conveyorId = false, petiteBuche = '0', grandeBuche = '0', patisserie = '0' , patisserie2 = '0') {
    let formattedDate = $.datepicker.formatDate('yy-mm-dd', date);
    let today = new Date();
    let formattedTodayDate = $.datepicker.formatDate('yy-mm-dd', today);
    let endPeriod = new Date();
    var currentDay = date.getDay();
    let parsedDate = new Date(formattedDate); 
    let dayDate = parsedDate.getDay();
    let deliveryData = arrayDeliveryData[conveyorId] || {};

    let intervalDays = (petiteBuche == '1' || grandeBuche == '1') ? 90 : 10;
    endPeriod.setDate(today.getDate() + intervalDays);
    let closedDay = new Date(storeClosingHours);

    // Checking if the chosen date is today and the store is closed
    if (formattedDate == formattedTodayDate && today > closedDay) {
        return false; // If the store is closed, turn off today's date
    }

    // Checking the maximum collection period (10 days)
    if (date > endPeriod) {
        return false; // Disable dates beyond 10 days
    }

    // Specific dates for "Petite Buche" and "Grande Buche"
    var storeIdNoel = $('#livr_relais').val();
    if (!storeIdNoel) {
        storeIdNoel = $('[name="livr_dom"]').val();
    }
    storeIdNoel = Number(storeIdNoel);
    const { petiteBucheDates, grandeBucheDates } = getBucheDates(storeIdNoel);
    // Date format for comparison with specific dates
    var formatDate = $.datepicker.formatDate('mm-dd', date);

    // Initialize a variable for return
    let isValidDate = true;

    // Condition for "Petite Buche"
    if (petiteBuche == '1') {
        // If the selected date is not in the valid dates for Petite Bûche
        if (!petiteBucheDates.includes(formatDate)) {
            isValidDate = false; // Disable if the date is not valid for Petite Bûche
        }
    }

    // Condition for "Grande Bûche"
    if (grandeBuche == '1') {
        // If the selected date is not in the valid dates for Grande Bûche
        if (!grandeBucheDates.includes(formatDate)) {
            isValidDate = false; // Disable if the date is not valid for Grande Bûche
        }
    }

    // Condition for pastry
    if (patisserie == '1') {
        let todayDate =  new Date();
        todayDate.setDate(today.getDate());
        let todayFormattedDate = $.datepicker.formatDate('yy-mm-dd', todayDate);
        // Check if today is before 12 p.m
        if (today.getHours() < 12) {
            let tomorrow = new Date();
            tomorrow.setDate(today.getDate() + 1);
            let formattedTomorrowDate = $.datepicker.formatDate('yy-mm-dd', tomorrow);
            // If the selected date is not tomorrow, it is valid
            if (formattedDate != formattedTomorrowDate && formattedDate === todayFormattedDate) {
                isValidDate = false; // Disable if we try to choose tomorrow
            }
        } else {
            // If after 12 p.m., deactivate tomorrow
            let tomorrow = new Date();
            tomorrow.setDate(today.getDate() + 1);
            let formattedTomorrowDate = $.datepicker.formatDate('yy-mm-dd', tomorrow);
            if (formattedDate == formattedTomorrowDate || formattedDate === todayFormattedDate) {
                isValidDate = false; // Disable if we try to choose tomorrow after 12 p.m.
            }
        }
    }

    // Condition for pastry
    if (patisserie2 == '1') {
        let todayDate =  new Date();
        todayDate.setDate(today.getDate());
        let todayFormattedDate = $.datepicker.formatDate('yy-mm-dd', todayDate);
        if (today.getHours() < 12) {
            let tomorrow = new Date();
            tomorrow.setDate(today.getDate() + 1);
            let formattedTomorrowDate = $.datepicker.formatDate('yy-mm-dd', tomorrow);
            if (dayDate == 1 || dayDate == 2) {
                isValidDate = false; 
            }
            // If the selected date is not tomorrow, it is valid
            if (formattedDate != formattedTomorrowDate && formattedDate === todayFormattedDate) {
                isValidDate = false; // Disable if we try to choose tomorrow
            }

        } else {
            // If after 12 p.m., deactivate tomorrow
            let tomorrow = new Date();
            tomorrow.setDate(today.getDate() + 1);
            let formattedTomorrowDate = $.datepicker.formatDate('yy-mm-dd', tomorrow);
            if (formattedDate == formattedTomorrowDate || formattedDate === todayFormattedDate) {
                isValidDate = false; // Disable if we try to choose tomorrow after 12 p.m.
            }
            if (dayDate == 1 || dayDate == 2) {
                isValidDate = false; 
            }
        }
    }

    if (isValidDate && date <= endPeriod) {
        // Checking if the store is exceptionally open on the selected day
        let storeExceptionalDays = deliveryData.storeExceptionalDays ? deliveryData.storeExceptionalDays.split('|') : [];
        if (storeExceptionalDays.includes(formattedDate)) {
            return [true]; // Enable if the store is exceptionally open on the selected day
        }

        // Checking if the store is closed on the selected day
        let storeClosingDays = deliveryData.storeClosingDays ? deliveryData.storeClosingDays.split('|') : [];
        if (storeClosingDays.includes('7')) {
            storeClosingDays.push('0');
        }

        if (storeClosingDays.includes(currentDay.toString())) {
            return false; // Disable if the store is closed on the selected day
        }

        let arrayDeliveryDates = deliveryData.disabledDates ? deliveryData.disabledDates.split('|') : [];
        if (arrayDeliveryDates.includes(formattedDate)) {
            return false; // Disable if the date is in the disabled dates
        }
    }

    // Return array of valid dates
    return [isValidDate && date <= endPeriod];
}

/**
 * Retrieves the available dates for petite and grande buche based on the store ID.
 *
 * @author Jordan HOURI <jh@wshop.com>
 *
 * @param {number} storeId - The ID of the store.
 * @returns {Object} An object containing two arrays:
 * - petiteBucheDates {string[]} - The dates available for petite buche.
 * - grandeBucheDates {string[]} - The dates available for grande buche.
 */
function getBucheDates(storeId) {
    let petiteBucheDates = [];
    let grandeBucheDates = [];

    if ([45, 42, 54, 48, 44].includes(storeId)) {
        petiteBucheDates = ['12-07', '12-08', '12-13', '12-14', '12-15', '12-20', '12-21', '12-22', '12-23', '12-24', '12-31'];
        grandeBucheDates = ['12-13', '12-14', '12-15', '12-20', '12-21', '12-22', '12-23', '12-24', '12-31'];
    } else if ([13, 60, 38].includes(storeId)) {
        petiteBucheDates = ['12-23', '12-24'];
        grandeBucheDates = ['12-23', '12-24'];
    }
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const now = (urlParams.has('test_date') && (window.location.hostname.includes('localhost') || window.location.hostname.includes('preprod')))
    ? new Date(urlParams.get('test_date'))
    : new Date();

    const dayOfWeek = now.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
    const hour = now.getHours();
    const minute = now.getMinutes();
    const isAfterFridayNoon = (dayOfWeek === 5 && (hour > 12 || (hour === 12 && minute > 0))); // friday after 12h01
    const isSaturday = dayOfWeek === 6;
    const isSunday = dayOfWeek === 0;
    const today = formatDate(now);
    const isAfterThursdayNoon = (dayOfWeek === 4 && (hour > 12 || (hour === 12 && minute > 0))); // thursday after 12h01
    // Filter only Fridays present in dates
    const fridayDates = petiteBucheDates.filter(date => isFriday(date, now));
    // Calculate specific days to disable
    let excludeDates = [];

    if (isAfterThursdayNoon) {
        excludeDates.push(getNextDate(now, 1)); 
    }

    if (isAfterFridayNoon) {
        excludeDates.push(getNextDate(now, 0)); // friday
        excludeDates.push(getNextDate(now, 1)); // saturday
        excludeDates.push(getNextDate(now, 2)); // sunday
        excludeDates.push(getNextDate(now, 3)); // monday
    } else if (isSaturday) {
        excludeDates.push(getNextDate(now, 0)); // saturday
        excludeDates.push(getNextDate(now, 1)); // sunday
        excludeDates.push(getNextDate(now, 2)); // monday
    } else if (isSunday) {
        excludeDates.push(getNextDate(now, 0)); // sunday
        excludeDates.push(getNextDate(now, 1)); // monday
    } else if (dayOfWeek === 1) {
        excludeDates.push(getNextDate(now, 0)); // monday
    }
    excludeDates.push(getNextDate(now, 0)); // Lundi
    // Filter dates to exclude specific days and past dates
    const excludeDateStrings = excludeDates.map(date => formatDate(date));
    petiteBucheDates = petiteBucheDates.filter(date => !excludeDateStrings.includes(date) && date >= today);
    grandeBucheDates = grandeBucheDates.filter(date => !excludeDateStrings.includes(date) && date >= today);

    return { petiteBucheDates, grandeBucheDates };

    // Helper: Get a specific date (D+N)
    function getNextDate(baseDate, daysToAdd) {
        const nextDate = new Date(baseDate);
        nextDate.setDate(nextDate.getDate() + daysToAdd);
        return nextDate;
    }

    // Helper: Format a date as MM-DD
    function formatDate(date) {
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${month}-${day}`;
    }

    // Helper: Check if a date in 'MM-DD' corresponds to a Friday
    function isFriday(dateStr, currentDate) {
        const [month, day] = dateStr.split('-').map(Number);
        const date = new Date(currentDate.getFullYear(), month - 1, day);
        return date.getDay() === 5; // 5 correspond is friday
    }
}

/**
 * Fetches the delivery date data for a specific store and updates the delivery data array.
 *
 * @param {number} storeId - The ID of the store to fetch delivery data for.
 * @param {number} conveyorId - The ID of the conveyor to update delivery data for.
 *
 * @returns {void}
 *
 * @author Stefane DR <sdr@wshop.com>
 */
function updateStoreDeliveryData(storeId, conveyorId) {
    $.ajax({
        url: path_relative_root + create_link('ajax_get_store_delivery_data'),
        type: "get",
        data: {store_id: storeId},
        success: function(response) {
            var response = JSON.parse(response);

            if (response.success) {
                var store = response.store;

                arrayDeliveryData[conveyorId].disabledDates = (store.disabledDates !== undefined) ? store.disabledDates : '';
                arrayDeliveryData[conveyorId].storeId = (store.storeId !== undefined) ? store.storeId : '';
                arrayDeliveryData[conveyorId].storeClosingDays = (store.storeClosingDays !== undefined) ? store.storeClosingDays : '';
                arrayDeliveryData[conveyorId].storeClosingHours = (store.storeClosingHours !== undefined) ? store.storeClosingHours : '';
                arrayDeliveryData[conveyorId].storeExceptionalDays = (store.storeExceptionalDays !== undefined) ? store.storeExceptionalDays : '';
            } else {
                alert('Erreur : ' + Translator.translate(response.error));
            }
        }
    })
}
